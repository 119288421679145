.gradient-box {
    border: 3px solid #FFD500;
    border-radius: 41px;
    backdrop-filter: blur(6px) brightness(0.6);
    height: 100%;
   
}
.gradient-box-text-holder{
    padding: 53px 70px;
    height: 100%;
}
.gradient-box-text-holder p{
    font-family: Montserrat-Medium;
    text-align: start;
    color: white;
    line-height: 22px;
    margin: 0;
}
.gradient-box-text-holder p {
    font-size: 20px;
}

@media (max-width: 1000px) {
    .gradient-box-text-holder{
        padding: 40px 35px;
       
    }
}
@media (max-width: 600px) {
    .gradient-box-text-holder p {
        font-size: 15px;
        line-height: 18px;
    }
}