.footer {
    width: 100%;
    color: white;
    text-align: center;
    background-color: #001736;
}

.footer-inner-container {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    max-width: 550px;
    margin: auto;
}

.footer-inner-container ul {
    padding: 0;
    text-align: left;
    margin: 0 auto;
}

.footer-li {
    padding: 2px 0;
    list-style-type: none;
    cursor: pointer;
}

.footer-logos {
    display: flex;
    flex-direction: column;
}

.logo {
    height: 54px;
}

.f {
    width: 100%;
}

.left-list li{
    text-align: right;
}

.right-list li{
    text-align: left;
}

.description {
    max-width: 600px;
    padding: 0px 10px;
    padding-bottom:10px;
    margin: auto;
    font-size: 13px;
    line-height: 17px;
}

/* mobile */

.footer-mobile .f {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.footer-mobile ul {
    width: 100%;
}

.mobile-footer-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.footer-list-title {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0.3em;
}

@media (min-width: 665px) {
    .footer-mobile {
        display: none;
    }
}

@media (max-width: 665px) {
    .footer-desktop {
        display: none;
    }

    .footer-mobile {
        display: flex;
    }

    .description {
        display: none;
    }

    .footer-li {
        font-size: 14px;
        line-height: 19px;
    }
}

@media (max-width: 465px) {
    .mobile-footer-logos {
        width: 100%;
    }
    .mobile-footer-logos img {
        width: 45%;
        height: 100% !important;
    }
}