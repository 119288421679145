.b2b-uoter-box {
    max-width: 1342px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    backdrop-filter: blur(5px) brightness(1.1);
    border-radius: 29px;
}
.b2b-title {
    line-height: 40px;
    font-size: 2.5em;
    font-family: Montserrat-Medium;
    text-align: start;
    color: white;
    margin: 0;
}
.b2b-scroll-box{
    max-height: 494px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: #707070 #000;
    scrollbar-width: thin;
}
.b2b-scroll-box::-webkit-scrollbar {
    width: 5px;
    background-color: #000;
    border-radius: 6px;
  }
  .b2b-scroll-box::-webkit-scrollbar-thumb{
    background-color: #707070;
    border-radius: 6px;
  }
.b2b-scroll-box p {
    width: 98%;
    font-size: 1.4em !important;
}

@media (max-width: 880px) {
    .b2b-title {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .b2b-uoter-box {
        width: 100%;
    }
    .b2b-title{
        font-size: 20px;
        line-height: 28px;
    }
}
