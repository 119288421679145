.background {
  padding: 115px 0px 80px 0px;
  margin: auto;
  color: white;
  font-size: 16px;
  width: 100%;
}

.pokerRulesOverlay {
  position: absolute;
  background: linear-gradient(#000000, #545454);
  z-index: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  background-image: url('../../assets/images/howtoplay/background-overlay.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.background-inner-container {
  padding-left: 123px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 3.7em;
  min-height: 12em;
}

.left-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 20px;
}

.right-container {
  max-width: 28em;
  right: 20px;
  display: flex;
  flex-direction: column;
}

.line {
  width: 66px;
  height: 17px;
  background-color: white;
}

/* howToPlay */
.home-button {
  margin-left: 65px;
  width: 4em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home-button img {
  width: 100%;
}

.button {
  max-width: 12em;
  font-size: 1.5em !important;
  text-shadow: 0px 4px 7px #000000;
  background: radial-gradient(rgb(0, 142, 89, 100%), rgb(0, 7, 8, 0%));
  border: solid 1px white;
  border-radius: 10px;
  font-weight: 700;
  padding: 1em 1.1em;
  margin-left: 122px;
  text-align: center;
  cursor: pointer;
}

/* background-media */
@media screen and (max-width: 1500px) {
  .background {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .background {
    font-size: 13px;
  }
}

@media screen and (max-width: 1025px) {
  .background {
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .top-container {
    min-height: 0px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 950px) {
  .page-container {
    gap: 10px;
  }

  .right-container {
    display: none;
  }

  .left-container {
    width: 100%;
    flex-direction: column-reverse;
  }

  .home-button {
    position: absolute;
    bottom: 0;
    right: 18px;
  }

  .cards-container {
    padding: 0px 20px;
  }

  .top-container {
    padding-right: 0px;
  }

  .button {
    margin: 0px 20px;
    width: 100%;
    margin-left: 0px;
    margin: auto;
    max-width: 55%;
  }

  .background-inner-container {
    padding: 0px 66px;
  }
}

@media screen and (max-width: 600px) { 
  .background-inner-container {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 360px) {
  .background {
    font-size: 9px;
  }

  .button {
    max-width: 70%;
  }
}

@media screen and (max-width: 332px) {
  .home-button {
    position: absolute;
    left: 34px;
    top: -41px;
    margin-left: 0;
    width: 30px;
  }
}
