nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 13px 0;
    z-index: 123123123;
}

.nav-inner {
    padding: 0 20px;
    justify-content: flex-start;
}

.left-align-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
    width: 100%;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
}

.items {
    display: flex;
    margin-right: 40px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.navbar-logo {
    max-width: 60px;
}

.navbar-logo img {
    max-width: 100%;
}

.navbar-store-icons {
    max-width: 270px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.navbar-store-icons img {
    width: 50%;
    cursor: pointer;
}

/* mobile styles */
.mobile-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: #001736;
}

.m-li-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 119px;
}

.m-li-div img {
    width: 100%;
}

.items-mobile {
    display: flex;
    color: #fff;
    cursor: pointer;
}

.mobile-nav-container {
    position: absolute;
    background: #001736;
    width: 100%;
    z-index: 0;
    opacity: 0;
    animation: fadeOut 0.5s ease-in-out;
}

.mobile-nav-container li {
    cursor: default;
    pointer-events: none;
}

.mobile-nav-container ul, .toggleMenu ul {
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.py-10 {
    margin-right: 0px;
}

.toggleMenu {
    position: absolute;
    background: #001736;
    width: 100%;
    z-index: 1;
    animation: fadeIn 0.5s ease-in-out; 
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    1000% {
      opacity: 0;
    }
  }  

@media screen and (min-width: 1500px) {
    .navbar-logo img {
        max-width: 70px;
    }
    .items {
        font-size: 23px;
    }
}

@media screen and (max-width: 1500px) {
    .left-align-div {
        padding-right: 2em;
    }
} 

@media screen and (max-width: 1381px) {
    .items {
        margin-right: 20px;
    }
}

@media screen and (max-width: 1367px) {
    .left-align-div {
        padding-right: 1.8em;
    }
}

@media screen and (max-width: 1219px) {
    .items {
        margin-right: 15px;
    }

    .navbar-logo img {
        max-width: 50px;
    }

    .navbar-store-icons {
        max-width: 210px;
    }

    .left-align-div {
        padding-right: 1.9em;
    }
}

@media screen and (max-width: 1110px) {
    .items {
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .list {
        flex-direction: column;
        height: auto;
    }

    .navbar-store-icons {
        max-width: 270px;
    }

    .items {
        font-size: 20px;
    }
}
